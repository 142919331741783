/* Drupal Shim for IPUMS Static Base
 * Version 1.1
 *    change to common-isrdi-extends 1.1 to support tabular font fix
 */

@import 'common-isrdi-variables.1.0';
@import 'common-isrdi-extends.1.1';
@import 'base-ipums-variables.1.0';

// ***
// * BEGIN Drupal Styling for logged in users
// ***
.pane-pane-messages {
  margin-left: -1 * $width_gutter;
  margin-right: -1 * $width_gutter;

  .messages--status {
    color:$color_info;
    margin:1rem;
    background-color:#fff;
    border:1px solid $color_info;
  }
  .messages--warning {
    color:$color_warning;
    margin:1rem;
    background-color:#fff;
    border:1px solid $color_warning;
  }
}
ul.tabs-primary {
  position:relative;
  line-height:2.4rem;
  margin:0 0 0.5em 0;

  li {
    position:relative;
    z-index:0;
    margin: 0 0.1rem;
    padding: 0 1rem;
    border: 1px solid $color_primary;
    background: $color_secondary_med_dark;
    display: inline-block;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    &:hover {
       background: $color_tertiary;
    }
    &.is-active {
      z-index:2;
      border-bottom-color: $color_near_white;
      background: $color_near_white;
      a {
        color: $color_primary;
      }
    }
    a {
      color: $color_near_white;
    }
  }
  &:before {
    z-index:1;
  }
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid $color_primary;
    z-index: 1;    
  }
}
// ***
// * END Drupal Styling for logged in users
// ***

// ***
// * BEGIN Basic Layout
// ***

/* drupal puts additional stuff between the body and the header */
.main-class-wrapper > header {
  margin-top:$height_userbar;
}

/* top nav is not in a nav element so must be repeated */
div[id="topNav"] {
// navigation appearing at top right of page
  position:absolute;
  top:7.2rem;
  left:50%;
  width:50%;
  & + div[id="picturebar"] {
    margin-top:2.8rem;
  }
}
main, div[id="main"] {
  overflow: visible; // for some reason, this is auto in base-ipums -- test well
}
.width-full {
  header {
    div[id="topNav"] {
      width:52.92rem;
    }
  }
}
// ***
// * END Basic Layout
// ***

// ***
// * BEGIN Visual Styles for Page Structural Elements
// ***

/* top nav is not in a nav element so must be repeated */
div[id="topNav"] {
// navigation appearing at top right of page
  letter-spacing: 0.035em;
  border-top:1px $color_secondary_medium solid;

  ul {
    margin-top:0.7rem;
  }
  .menu {
    @extend %menu-list;
    li {
      color: $color_near_white;
      &:first-child {
        padding-left:0;
      }
      a {
        text-decoration: none;
        color: $color_near_white;
        &:hover {
          color:$color_primary;
        }
      }
      text-transform:uppercase;
      font-size:1.4rem;
    }
  }
}

/* non drupal sites set these in the html */
section[id="supported-by"] {
  img {
    width:auto;
    height:5.5rem;
  }
}

/* footer menu can't easily get class list-menu */
footer {
  ul.menu {
    @extend %menu-list;
    justify-content: center;
  }
  a:link, a:visited {
    color:$color_near_white;
  }
  a:hover, a:active {
    color:$color_secondary_medium;
  }
}
// ***
// * END Visual Styles for Page Structural Elements
// ***

//utility to left align columns using table classes (default)
//this is a more specific version of styles from isrdi-common
.page {
  .align-left td, 
  .align-left th,
  .align-left-col-1 td:nth-child(1),
  .align-left-col-2 td:nth-child(2),
  .align-left-col-3 td:nth-child(3),
  .align-left-col-4 td:nth-child(4),
  .align-left-col-5 td:nth-child(5),
  .align-left-col-6 td:nth-child(6),
  .align-left-col-7 td:nth-child(7),
  .align-left-col-8 td:nth-child(8),
  .align-left-col-9 td:nth-child(9),
  .align-left-col-10 td:nth-child(10),
  .align-left-col-11 td:nth-child(11),
  .align-left-col-12 td:nth-child(12),
  .align-left-col-13 td:nth-child(13),
  .align-left-col-14 td:nth-child(14),
  .align-left-col-15 td:nth-child(15),
  .align-left-col-1 th:nth-child(1),
  .align-left-col-2 th:nth-child(2),
  .align-left-col-3 th:nth-child(3),
  .align-left-col-4 th:nth-child(4),
  .align-left-col-5 th:nth-child(5),
  .align-left-col-6 th:nth-child(6),
  .align-left-col-7 th:nth-child(7),
  .align-left-col-8 th:nth-child(8),
  .align-left-col-9 th:nth-child(9),
  .align-left-col-10 th:nth-child(10),
  .align-left-col-11 th:nth-child(11),
  .align-left-col-12 th:nth-child(12),
  .align-left-col-13 th:nth-child(13),
  .align-left-col-14 th:nth-child(14),
  .align-left-col-15 th:nth-child(15) {
    text-align: left;
  }
  //utility to center columns using table classes
  .align-center td, 
  .align-center th,
  .align-center-col-1 td:nth-child(1),
  .align-center-col-2 td:nth-child(2),
  .align-center-col-3 td:nth-child(3),
  .align-center-col-4 td:nth-child(4),
  .align-center-col-5 td:nth-child(5),
  .align-center-col-6 td:nth-child(6),
  .align-center-col-7 td:nth-child(7),
  .align-center-col-8 td:nth-child(8),
  .align-center-col-9 td:nth-child(9),
  .align-center-col-10 td:nth-child(10),
  .align-center-col-11 td:nth-child(11),
  .align-center-col-12 td:nth-child(12),
  .align-center-col-13 td:nth-child(13),
  .align-center-col-14 td:nth-child(14),
  .align-center-col-15 td:nth-child(15),
  .align-center-col-1 th:nth-child(1),
  .align-center-col-2 th:nth-child(2),
  .align-center-col-3 th:nth-child(3),
  .align-center-col-4 th:nth-child(4),
  .align-center-col-5 th:nth-child(5),
  .align-center-col-6 th:nth-child(6),
  .align-center-col-7 th:nth-child(7),
  .align-center-col-8 th:nth-child(8),
  .align-center-col-9 th:nth-child(9),
  .align-center-col-10 th:nth-child(10),
  .align-center-col-11 th:nth-child(11),
  .align-center-col-12 th:nth-child(12),
  .align-center-col-13 th:nth-child(13),
  .align-center-col-14 th:nth-child(14),
  .align-center-col-15 th:nth-child(15) {
    text-align: center;
  }
  //utility to right align columns using table classes
  .align-right td, 
  .align-right th,
  .align-right-col-1 td:nth-child(1),
  .align-right-col-2 td:nth-child(2),
  .align-right-col-3 td:nth-child(3),
  .align-right-col-4 td:nth-child(4),
  .align-right-col-5 td:nth-child(5),
  .align-right-col-6 td:nth-child(6),
  .align-right-col-7 td:nth-child(7),
  .align-right-col-8 td:nth-child(8),
  .align-right-col-9 td:nth-child(9),
  .align-right-col-10 td:nth-child(10),
  .align-right-col-11 td:nth-child(11),
  .align-right-col-12 td:nth-child(12),
  .align-right-col-13 td:nth-child(13),
  .align-right-col-14 td:nth-child(14),
  .align-right-col-15 td:nth-child(15),
  .align-right-col-1 th:nth-child(1),
  .align-right-col-2 th:nth-child(2),
  .align-right-col-3 th:nth-child(3),
  .align-right-col-4 th:nth-child(4),
  .align-right-col-5 th:nth-child(5),
  .align-right-col-6 th:nth-child(6),
  .align-right-col-7 th:nth-child(7),
  .align-right-col-8 th:nth-child(8),
  .align-right-col-9 th:nth-child(9),
  .align-right-col-10 th:nth-child(10),
  .align-right-col-11 th:nth-child(11),
  .align-right-col-12 th:nth-child(12),
  .align-right-col-13 th:nth-child(13),
  .align-right-col-14 th:nth-child(14),
  .align-right-col-15 th:nth-child(15) {
    text-align: right;
  }
  //utility to vertically middle align columns using table or row classes
  .align-middle td, 
  .align-middle th {
    vertical-align:middle;
  }
}