// base-ipums-static has $width_content hardcoded for userbar.
// if it changes here, be sure to change it there as well.
$width_content: 88.2rem; 
$width_sidenav: 19.2rem;
$width_menu_indent: 1.5rem;

$height_userbar: 2.4rem;
$height_header: 12.7rem;
$height_top_padding: 4.4rem;

// the following are only needed if a picture bar is going to be used
$num_pictures: 6;
$ratio_aspect: 1.5; // width/height of pictures
$size_picturebar_border: 0.3rem;
// width for a pic = total width minus gutters width divided by the number of pics
$width_picture: ( $width_content - (($num_pictures - 1) * $size_picturebar_border )) / $num_pictures;
$height_picture: $width_picture / $ratio_aspect;
